<template>
    <div>
        <b-card>
            <datatable-header
                :title="$tc('modules.people.description', 2)"
                :genericSearchEnabled="true"
                :searchTermPlaceHolder="$t('common.search')"
                @filterUpdated="onFilterUpdate"
            >
                <template #buttons>
                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'create-people-modal'
                            )
                        "
                    >
                        {{
                            $t("common.crud.addNew", {
                                resource: $tc(
                                    "modules.people.description",
                                    1
                                ).toLowerCase(),
                            }).toString()
                        }}
                    </b-button>
                </template>

                <template #advancedSearch="{ expanded, onFilterChange }">
                    <DatatableFilter
                        :availableDatatableFilters="availableDatatableFilters"
                        :expanded="expanded"
                        @filterUpdated="onFilterUpdate"
                        @onFilterChange="onFilterChange"
                    ></DatatableFilter>
                </template>
            </datatable-header>

            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: true,
                    mode: 'pages',
                    perPage: dataTableOptions.pagination.perPage,
                }"
                :rows="rows"
                :rtl="direction"
                :searchOptions="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :totalRows="dataTableOptions.pagination.totalRows"
                mode="remote"
                theme="my-theme"
                @on-row-click="onRowClick"
                @on-page-change="handleChangePage"
                @onPageSizeChange="handlePageSizeChange"
            >
                <template #table-row="props">
                    <span v-if="props.column.field === 'relationships'">
                        <span
                            v-for="relation in props.row.relations"
                            :key="relation.id"
                        >
                            <b-badge>
                                {{ relation.description }}
                            </b-badge>
                        </span>
                    </span>
                    <span v-else-if="props.column.field === 'classifications'">
                        <span
                            v-for="classification in props.row.classifications"
                            :key="classification.id"
                        >
                            {{ classification.description }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'emails'">
                        <span v-for="email in props.row.emails" :key="email.id">
                            {{ email.address }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'phones'">
                        <span v-for="phone in props.row.phones" :key="phone.id">
                            {{ phone.number }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'status'">
                        <b-badge
                            v-if="props.row.status"
                            :variant="
                                statusVariant(props.row.status.description)
                            "
                        >
                            {{ props.row.status.description }}
                        </b-badge>
                    </span>

                    <span v-else-if="props.column.field === 'gender'">
                        <span v-if="props.row.gender">
                            {{ props.row.gender.description }}
                        </span>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </datatable>
        </b-card>
    </div>
</template>

<script>
import { BBadge, BCard, BButton } from "bootstrap-vue"
import store from "@/store/index"
import Api from "@/services/api/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import DatatableHeader from "@/views/components/utilis-spa/datatable/DatatableHeader.vue"
import DatatableFilter from "@/views/components/utilis-spa/datatable/DatatableFilter.vue"

export default {
    components: {
        DatatableHeader,
        BCard,
        Datatable,
        BBadge,
        DatatableFilter,
        BButton,
    },
    data() {
        return {
            dataTableOptions: {
                pagination: {
                    perPage: 5,
                    totalRows: 0,
                },
            },
            isLoading: true,
            dir: false,
            isFilterExpanded: false,
            columns: [
                {
                    label: this.$t("common.status"),
                    field: "status",
                },
                {
                    label: this.$t("common.crud.name"),
                    field: "name",
                },
                {
                    label: this.$t("modules.people.gender"),
                    field: "gender",
                },
                {
                    label: this.$t("modules.people.documentNumber"),
                    field: "documentNumber",
                },
                {
                    label: this.$t("common.relationships"),
                    field: "relationships",
                },
                {
                    label: this.$t("common.classifications"),
                    field: "classifications",
                },
                {
                    label: this.$tc("common.email.description", 2),
                    field: "emails",
                },
                {
                    label: this.$tc("common.phone.description", 2),
                    field: "phones",
                },
                {
                    label: this.$t("modules.people.birthdate"),
                    field: "birthDate",
                    formatFn: this.formatDate,
                },
                {
                    label: this.$t("common.crud.createdAt"),
                    field: "createdAt",
                    formatFn: this.formatDate,
                },
            ],
            rows: [],
            searchTerm: "",
            availableDatatableFilters: {},
            service: Api,
            apiFilter: null,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },

        statusVariant() {
            const statusColor = {
                Ativo: "light-primary",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
    },
    created() {
        this.getPeople()
        this.getAvailableFilters()
    },
    methods: {
        formatDate(date) {
            return date
                ? this.$moment(date).format(this.$t("customFormats.date"))
                : ""
        },
        handleChangePage(page) {
            this.isLoading = true
            this.getPeople({ selectedPage: page.currentPage })
        },
        handlePageSizeChange(pageSize) {
            this.dataTableOptions.pagination.perPage = pageSize
            this.getPeople()
        },
        async getPeople(options = {}) {
            try {
                this.isLoading = true
                const { pagination: paginationOptions } = this.dataTableOptions
                const { selectedPage } = options

                const peopleResponse = await this.service.findAll("people", {
                    // RELATED RESOURCES
                    include:
                        "gender,classifications,origin,status,relations,phones,emails",

                    // SPARSE FIELDSETS
                    fields: {
                        genders: "description",
                        "person-classifications": "description",
                        "person-relationships": "description",
                        status: "description",
                        origin: "description",
                        emails: "address,isMainEmailAddress",
                        phones: "number,isMainPhoneNumber",
                    },

                    // FILTERS
                    filter: this.apiFilter,

                    // PAGINATION
                    page: {
                        size: paginationOptions.perPage,
                        number: selectedPage || 1,
                    },
                })

                this.rows = peopleResponse.data
                const paginationMetaData = peopleResponse.meta.page
                this.dataTableOptions.pagination.totalRows =
                    paginationMetaData.total
            } finally {
                this.isLoading = false
            }
        },
        async getAvailableFilters() {
            this.availableDatatableFilters.name = DatatableFilterUtils.createFilter.text(
                "name",
                {
                    label: this.$t("common.crud.name"),
                    placeholder: this.$t("common.searchBy", {
                        field: this.$t("common.crud.name").toLowerCase(),
                    }),
                }
            )

            this.availableDatatableFilters.email = DatatableFilterUtils.createFilter.text(
                "emails",
                {
                    label: this.$tc("common.email.description", 1),
                    filterAttribute: "address",
                    placeholder: this.$t("common.searchBy", {
                        field: this.$tc(
                            "common.email.description",
                            1
                        ).toLowerCase(),
                    }),
                }
            )

            this.availableDatatableFilters.birthDate = DatatableFilterUtils.createFilter.date(
                "birthDate",
                {
                    label: this.$t("modules.people.birthdate"),
                    placeholder: this.$t("customFormats.date"),
                }
            )

            const classificationsResponse = await this.service.findAll(
                "person-classifications",
                {
                    fields: { "person-classifications": "description" },
                }
            )

            this.availableDatatableFilters.classifications = DatatableFilterUtils.createFilter.list(
                "classifications",
                classificationsResponse.data.map(classification => ({
                    value: classification.id,
                    label: classification.description,
                })),
                {
                    label: this.$t("common.classifications"),
                    filterAttribute: "id",
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t(
                            "common.classifications"
                        ).toLowerCase(),
                    }),
                }
            )

            const relationshipsResponse = await this.service.findAll(
                "person-relationships",
                {
                    fields: { "person-relationships": "description" },
                }
            )

            this.availableDatatableFilters.relationships = DatatableFilterUtils.createFilter.list(
                "relations",
                relationshipsResponse.data.map(relationship => ({
                    value: relationship.id,
                    label: relationship.description,
                })),
                {
                    filterAttribute: "id",
                    label: this.$t("common.relationships"),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t("common.relationships").toLowerCase(),
                    }),
                }
            )

            const statusResponse = await this.service.findAll("status", {
                fields: { status: "description" },
            })

            this.availableDatatableFilters.status = DatatableFilterUtils.createFilter.list(
                "status",
                statusResponse.data.map(status => ({
                    value: status.id,
                    label: status.description,
                })),
                {
                    filterAttribute: "id",
                    label: this.$t("common.status"),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t("common.status").toLowerCase(),
                    }),
                }
            )
        },
        onRowClick(params) {
            this.$router.push({
                name: "view-people",
                params: { id: params.row.id },
            })
        },
        onFilterExpandedChange() {
            this.isFilterExpanded = !this.isFilterExpanded
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
            this.getPeople()
        },
    },
}
</script>
